import Vue from "vue";
import Vuex from "vuex";
import client from "@/api/client";
import { OrderManager } from "@/methods/order_manager";
import session from "@/methods/session_storage";
import adminManager from "@/methods/admin_manager";
import moment from "moment-timezone";
import router from "@/router/router.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    (store) => {
      store.$router = router;
    },
  ],
  state: {
    data_interval: null,
    hour_data_interval: null,
    last_resolution: {},
    last_resolution_time: "",
    orderManager: { orders: { confirmed: [], nonConfirmed: [], exported: [] } },
    ports: [],
    management_areas: [],
    all_ports: [],
    all_management_areas: [],
    areas: [],
    preferred_carriers: [],
    delegations: [],
    delegations_ports: [],
    orders_dashboard_info: {},
    savings_dashboard_info: {},
    suppliers_dashboad_info: {},
    suppliers_occupation: {},
    containers: [],
    coordinates: [],
    clients: [],
    alarms: [],
    selected_management_areas: [],
    selected_containers: [],
    selected_suppliers: [],
    selected_order_id: "",
    selected_dates: [],
    selected_port_for_historic_dashboard: [],
    selected_supplier_for_historic_dashboard: [],
    user: {},
    users: [],
    role: "",
    roles: { 2: "DISPATCHER", 5: "SUPERVISOR", 6: "ADMIN" },
    profileMode: null,
    break_combination_reasons: [],
    change_supplier_reasons: [],
    change_combination_reasons: [],
    review_changes: null,
    review_changes_formatted: [],
    coordinate_changes_formatted: [],
    supplier_area: [],
    urgent_services: { orders: [] },
    all_suppliers: [],
    port_metrics: [],
    supplier_metrics: [],
  },
  getters: {
    getRole: (state) => {
      return state.roles[state.role];
    },
    getProfileMode: (state, getters) => {
      return state.profileMode ? state.profileMode : getters.getRole;
    },
    getLastResolution: (state) => {
      return state.last_resolution;
    },
    getLastResolutionTime: (state) => {
      if (
        !state.last_resolution_time ||
        !state.last_resolution_time.last_solution
      ) {
        return "No hay datos";
      }

      const utcDate = moment.utc(state.last_resolution_time.last_solution);
      const timeZone = moment.tz.guess();
      const localDate = utcDate.tz(timeZone);
      const localTimeString = localDate.format("HH:mm");

      return localTimeString;
    },
    getEndResolutionTime: (state) => {
      if (
        !state.last_resolution_time ||
        !state.last_resolution_time.end_solution
      ) {
        return "No hay datos";
      }

      const newDate = new Date(state.last_resolution_time.end_solution);
      const localTimeString = newDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      return localTimeString;
    },
    getStartResolutionTime: (state) => {
      if (
        !state.last_resolution_time ||
        !state.last_resolution_time.start_solution
      ) {
        return "No hay datos";
      }

      const newDate = new Date(state.last_resolution_time.start_solution);
      const localTimeString = newDate.toLocaleString("es-ES", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });

      return localTimeString;
    },
    getConfirmedOrders: (state) => {
      const selectedManagementAreas = state.selected_management_areas;
      const selectedProviders = state.selected_suppliers;
      const selectedContainers = state.selected_containers;
      const selectedOrderId = state.selected_order_id;

      return state.orderManager.orders.confirmed
        .filter((order) => {
          const orderProviderId = order.supplier_id;
          const orderContainerId =
            order.type === "export"
              ? order.export.container_type
              : order.import.container_type;
          const orderManagementAreas =
            order.type === "combined"
              ? [
                  order.import.id_management_area,
                  order.export.id_management_area,
                ]
              : order.type === "export"
              ? [order.export.id_management_area]
              : [order.import.id_management_area];

          return (
            (selectedProviders?.length === 0 ||
              selectedProviders?.includes(orderProviderId)) &&
            (selectedContainers?.length === 0 ||
              selectedContainers?.includes(orderContainerId)) &&
            (selectedOrderId === "" ||
              (order.type === "combined" &&
                (order.import.id === selectedOrderId ||
                  order.export.id === selectedOrderId ||
                  order.import.commercial_number == selectedOrderId ||
                  order.export.commercial_number == selectedOrderId)) ||
              (order.type !== "combined" &&
                (selectedOrderId === order.id ||
                  selectedOrderId == order[order.type].commercial_number))) &&
            (selectedManagementAreas?.length === 0 ||
              orderManagementAreas.some((orderManagementArea) =>
                selectedManagementAreas.includes(orderManagementArea)
              ))
          );
        })
        .map((order) => ({
          ...order,
          checked: order.checked ? order.checked : false,
        }));
    },
    getNonConfirmedOrders: (state) => {
      const selectedManagementAreas = state.selected_management_areas;
      const selectedProviders = state.selected_suppliers;
      const selectedContainers = state.selected_containers;
      const selectedOrderId = state.selected_order_id;

      return state.orderManager.orders.nonConfirmed
        .filter((order) => {
          const orderProviderId = order.supplier_id;
          const orderContainerId =
            order.type === "export"
              ? order.export.container_type
              : order.import.container_type;
          const orderManagementAreas =
            order.type === "combined"
              ? [
                  order.import.id_management_area,
                  order.export.id_management_area,
                ]
              : order.type === "export"
              ? [order.export.id_management_area]
              : [order.import.id_management_area];

          return (
            (selectedProviders?.length === 0 ||
              selectedProviders?.includes(orderProviderId)) &&
            (selectedContainers?.length === 0 ||
              selectedContainers?.includes(orderContainerId)) &&
            (selectedOrderId === "" ||
              (order.type === "combined" &&
                (order.import.id === selectedOrderId ||
                  order.export.id === selectedOrderId ||
                  order.import.commercial_number == selectedOrderId ||
                  order.export.commercial_number == selectedOrderId)) ||
              (order.type !== "combined" &&
                (selectedOrderId === order.id ||
                  selectedOrderId == order[order.type].commercial_number))) &&
            (selectedManagementAreas?.length === 0 ||
              orderManagementAreas.some((orderManagementArea) =>
                selectedManagementAreas.includes(orderManagementArea)
              ))
          );
        })
        .map((order) => ({
          ...order,
          checked: order.checked ? order.checked : false,
        }));
    },
    getExportedOrders: (state) => {
      const selectedManagementAreas = state.selected_management_areas;
      const selectedProviders = state.selected_suppliers;
      const selectedContainers = state.selected_containers;
      const selectedOrderId = state.selected_order_id;

      return state.orderManager.orders.exported
        .filter((order) => {
          const orderProviderId = order.supplier_id;
          const orderContainerId =
            order.type === "export"
              ? order.export.container_type
              : order.import.container_type;
          const orderManagementAreas =
            order.type === "combined"
              ? [
                  order.import.id_management_area,
                  order.export.id_management_area,
                ]
              : order.type === "export"
              ? [order.export.id_management_area]
              : [order.import.id_management_area];

          return (
            (selectedProviders?.length === 0 ||
              selectedProviders?.includes(orderProviderId)) &&
            (selectedContainers?.length === 0 ||
              selectedContainers?.includes(orderContainerId)) &&
            (selectedOrderId === "" ||
              (order.type === "combined" &&
                (order.import.id === selectedOrderId ||
                  order.export.id === selectedOrderId ||
                  order.import.commercial_number == selectedOrderId ||
                  order.export.commercial_number == selectedOrderId)) ||
              (order.type !== "combined" &&
                (selectedOrderId === order.id ||
                  selectedOrderId == order[order.type].commercial_number))) &&
            (selectedManagementAreas?.length === 0 ||
              orderManagementAreas.some((orderManagementArea) =>
                selectedManagementAreas.includes(orderManagementArea)
              ))
          );
        })
        .map((order) => ({
          ...order,
          checked: order.checked ? order.checked : false,
        }));
    },
    getOrderIds: (state) => {
      let orderIds = state.orderManager.getOrderIds();
      let commercialNumbers = state.orderManager.getCommercialNumbers();
      orderIds.push(commercialNumbers);
      const urgentOrderIds = state.urgent_services.getUrgentOrderIds();
      orderIds.push(urgentOrderIds);
      const urgentCommercialNumbers =
        state.urgent_services.getUrgentCommercialNumbers();
      orderIds.push(urgentCommercialNumbers);

      return orderIds.flat();
    },
    getConfirmedOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_confirmed;
      }
      return sum;
    },
    getNonConfirmedOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getNonConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_unconfirmed;
      }
      return sum;
    },
    getExportedOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getExportedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_exported;
      }

      return sum;
    },
    getTotalOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getExportedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
        getters.getNonConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
        getters.getConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          } else {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_orders;
      }

      return sum;
    },
    getImportOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getExportedOrders.forEach((order) => {
          if (order.type === "import" || order.type === "combined") {
            sum++;
          }
        });
        getters.getNonConfirmedOrders.forEach((order) => {
          if (order.type === "import" || order.type === "combined") {
            sum++;
          }
        });
        getters.getConfirmedOrders.forEach((order) => {
          if (order.type === "import" || order.type === "combined") {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_imports;
      }
      return sum;
    },
    getExportOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getExportedOrders.forEach((order) => {
          if (order.type === "export" || order.type === "combined") {
            sum++;
          }
        });
        getters.getNonConfirmedOrders.forEach((order) => {
          if (order.type === "export" || order.type === "combined") {
            sum++;
          }
        });
        getters.getConfirmedOrders.forEach((order) => {
          if (order.type === "export" || order.type === "combined") {
            sum++;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_exports;
      }

      return sum;
    },
    getCombinedOrdersCount: (state, getters) => {
      let sum = 0;
      if (getters.getProfileMode == "DISPATCHER") {
        getters.getExportedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          }
        });
        getters.getNonConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          }
        });
        getters.getConfirmedOrders.forEach((order) => {
          if (order.type === "combined") {
            sum = sum + 2;
          }
        });
      } else {
        sum = state.orders_dashboard_info.total_combinations;
      }
      return sum;
    },
    getCombinedOrdersPercentage: (state, getters) => {
      const percentage = Math.round(
        (getters.getCombinedOrdersCount / getters.getTotalOrdersCount) * 100
      );
      const difference = 100 - percentage;
      const result = [percentage, difference];
      return result;
    },
    getOrdersInfo: (state) => {
      return state.orders_dashboard_info;
    },
    getTotalkilometersSaved: (state) => {
      return (
        parseInt(state.savings_dashboard_info.base_km) -
        parseInt(state.savings_dashboard_info.km)
      );
    },
    getEmptyKms: (state) => {
      return Math.round(state.savings_dashboard_info.percentage_empty_km * 100);
    },
    getSavingsPercentage: (state) => {
      const percentage = state.savings_dashboard_info.savings * 100;
      return percentage.toFixed(2);
    },
    getMeanSupplierDistance: (state) => {
      return state.suppliers_dashboad_info.mean_supplier_distance;
    },
    getMeanSupplierOccupationPercentage: (state) => {
      const percentage =
        state.suppliers_dashboad_info.mean_supplier_occupation_percentage * 100;
      return percentage.toFixed(2);
    },
    getSuppliersOccupation: (state) => {
      return state.suppliers_occupation?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    getContainers: (state) => {
      return state.containers.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    getClients: (state) => {
      return state.clients.filter((client) => client.is_client);
    },
    getUrgentServices: (state) => {
      const selectedManagementAreas = state.selected_management_areas;
      const selectedContainers = state.selected_containers;
      const selectedOrderId = state.selected_order_id;
      return state.urgent_services.orders
        .filter((order) => {
          const orderContainerId =
            order.type === "export"
              ? order.export.container_type
              : order.import.container_type;
          const orderManagementAreas =
            order.type === "export"
              ? [order.export.id_management_area]
              : [order.import.id_management_area];

          return (
            (selectedContainers?.length === 0 ||
              selectedContainers?.includes(orderContainerId)) &&
            (selectedOrderId === "" ||
              (order.type === "combined" &&
                (order.import.id === selectedOrderId ||
                  order.export.id === selectedOrderId ||
                  order.import.commercial_number == selectedOrderId ||
                  order.export.commercial_number == selectedOrderId)) ||
              (order.type !== "combined" &&
                (selectedOrderId === order.id ||
                  selectedOrderId == order[order.type].commercial_number))) &&
            (selectedManagementAreas?.length === 0 ||
              orderManagementAreas.some((orderManagementArea) =>
                selectedManagementAreas.includes(orderManagementArea)
              ))
          );
        })
        .map((order) => ({
          ...order,
          checked: order.checked ? order.checked : false,
        }));
    },
    getAllSuppliers: (state, getters) => {
      const result = [];
      const map = new Map();

      for (const item of getters.getSupplierMetrics) {
        if (item.id_supplier !== "ALL" && !map.has(item.id_supplier)) {
          map.set(item.id_supplier, true);
          result.push({ id: item.id_supplier, name: item.supplier_name });
        }
      }

      return result.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    getAllPorts: (state) => {
      return state.all_ports.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    getAllManagementAreas: (state) => {
      return state.all_management_areas.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    getPorts: (state) => {
      return state.ports.filter((port) => port.name);
    },
    getManagementAreas: (state) => {
      return state.management_areas;
    },
    getSelectedManagementAreas: (state) => {
      return state.selected_management_areas;
    },
    getSelectedSuppliers: (state) => {
      return state.selected_suppliers;
    },
    getSelectedContainers: (state) => {
      return state.selected_containers;
    },
    getSelectedOrderId: (state) => {
      return state.selected_order_id;
    },
    getAlarms: (state) => {
      let alarmsOrder = state.alarms
        .map((obj) => ({ ...obj, color: "var(--dark-green)" }))
        .sort(
          (a1, a2) => Date.parse(a2.created_at) - Date.parse(a1.created_at)
        );
      alarmsOrder.forEach((element) => {
        switch (element.criticality) {
          case 1:
            element.color = "var(--dark-red)";
            break;
          case 2:
            element.color = "var(--dark-yellow)";
            break;
          default:
            element.color = "var(--dark-green)";
            break;
        }
      });

      return alarmsOrder;
    },
    getUser: (state) => {
      return state.users.find((user) => user.id == session.getUserId());
    },
    getUsers: (state) => {
      return state.users;
    },
    getCoordinates: (state) => {
      return state.coordinate_changes_formatted
        .map((coordinate) => ({
          ...coordinate,
          checked: coordinate.checked ? coordinate.checked : false
        }))
        .sort((a, b) => a.id_location - b.id_location);
    },
    getReviewChanges: (state) => {
      return state.review_changes_formatted.map((review) => ({
        ...review,
        checked: review.checked ? review.checked : false,
      }));
    },
    getPreferredCarriers: (state) => {
      return state.preferred_carriers;
    },
    getDelegations: (state) => {
      return state.delegations;
    },
    getDelegationsPorts: (state) => {
      return state.delegations_ports;
    },
    getSupplierArea: (state) => {
      return state.supplier_area.map((supplier) => ({
        ...supplier,
        activated: !supplier.deactivated,
      }));
    },
    getAreas: (state) => {
      return state.areas;
    },
    getBreakCombinationReasons: (state) => {
      return state.break_combination_reasons;
    },
    getChangeSupplierReasons: (state) => {
      return state.change_supplier_reasons;
    },
    getChangeCombinationReasons: (state) => {
      return state.change_combination_reasons;
    },
    getSelectedDates: (state) => {
      return state.selected_dates;
    },
    getSelectedPortForHistoricDashboard: (state) => {
      return state.selected_port_for_historic_dashboard;
    },
    getSelectedSupplierForHistoricDashboard: (state) => {
      return state.selected_supplier_for_historic_dashboard;
    },
    getPortMetrics: (state) => {
      return state.port_metrics.map((obj) => ({
        ...obj,
        total_savings_percentage: parseFloat(
          (
            ((obj.km_without_combination - obj.km_with_combination) /
              obj.km_without_combination) *
            100
          ).toFixed(2)
        ),
        total_savings: obj.km_without_combination - obj.km_with_combination,
      }));
    },
    getHistoricPortMetrics: (state, getters) => (portSelected) => {
      const filteredObjects = getters.getPortMetrics.filter(
        (obj) => obj.id_port === portSelected
      );

      const total_combined = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_combined,
        0
      ).toFixed(2));

      const total_exports = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_exports,
        0
      ).toFixed(2));

      const total_imports = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_imports,
        0
      ).toFixed(2));

      const total_savings = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_savings,
        0
      ).toFixed(2));

      const km_without_combination_sum = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.km_without_combination,
        0
      ).toFixed(2));

      const km_with_combination_sum = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.km_with_combination,
        0
      ).toFixed(2));

      let totals_empty_km = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.empty_km,
        0
      ).toFixed(2));

      let totals_empty_km_percentage = Number((
        (totals_empty_km / km_without_combination_sum) * 100
      ).toFixed(2));

      let total_savings_percentage = Number((
        ((km_without_combination_sum - km_with_combination_sum) /
          km_without_combination_sum) *
        100
      ).toFixed(2));

      let total_combined_percentage = Number((
        (total_combined / (total_exports + total_imports)) * 100
      ).toFixed(2));

      total_savings_percentage = isNaN(total_savings_percentage)
        ? 0
        : total_savings_percentage;
      total_combined_percentage = isNaN(total_combined_percentage)
        ? 0
        : total_combined_percentage;
      totals_empty_km_percentage = isNaN(totals_empty_km_percentage)
        ? 0
        : totals_empty_km_percentage;

      const total_kilometers = km_without_combination_sum + km_with_combination_sum;
      const total_orders = total_exports + total_imports;

      return {
        total_combined,
        total_exports,
        total_imports,
        total_savings,
        total_savings_percentage,
        total_combined_percentage,
        totals_empty_km_percentage,
        totals_empty_km,
        total_kilometers,
        total_orders,
      };
},
    getHistoricPortData: (state) => {
      const groupedData = {};
      for (const item of state.port_metrics) {
        const port = item.id_port;

        if (!groupedData[port]) {
          groupedData[port] = {
            id_port: port,
            port_name: item.port_name,
            total_combined: 0,
            total_exports: 0,
            total_imports: 0,
            km_without_combination: 0,
            km_with_combination: 0,
            total_services: 0,
            count: 0,
          };
        }

        groupedData[port].total_combined += item.total_combined;
        groupedData[port].total_exports += item.total_exports;
        groupedData[port].total_imports += item.total_imports;
        groupedData[port].km_without_combination += item.km_without_combination;
        groupedData[port].km_with_combination += item.km_with_combination;
        groupedData[port].total_services += item.total_services;
        groupedData[port].count++;
      }

      return Object.values(groupedData).map((obj) => ({
        ...obj,
        total_savings_percentage: parseFloat(
          (
            ((obj.km_without_combination - obj.km_with_combination) /
              obj.km_without_combination) *
            100
          ).toFixed(2)
        ),
        total_savings: obj.km_without_combination - obj.km_with_combination,
      }));
    },
    getHistoricPortDataByDates: (state, getters) => (portSelected) => {
      return getters.getPortMetrics
        .filter((metric) => metric.id_port === portSelected)
        .map((metric) => {
          if (isNaN(metric.total_savings_percentage)) {
            metric.total_savings_percentage = 0;
          }
          return metric;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    getCombinedAndSavingPercentagesForPorts: (state, getters) => {
      let totalCombined = 0;
      let totalExportImport = 0;
      let totalKmWithCombination = 0;
      let totalKmWithoutCombination = 0;
      const data = getters.getHistoricPortDataByDates("ESTOT");

      for (let i = 0; i < data.length; i++) {
        totalCombined += data[i].total_combined;
        totalExportImport += data[i].total_exports + data[i].total_imports;
        totalKmWithCombination += data[i].km_with_combination;
        totalKmWithoutCombination += data[i].km_without_combination;
      }

      let percentageCombinations = (totalCombined / totalExportImport) * 100;
      let percentageSavings =
        ((totalKmWithoutCombination - totalKmWithCombination) /
          totalKmWithoutCombination) *
        100;

      percentageCombinations = isNaN(percentageCombinations)
        ? 0
        : Math.round(percentageCombinations);
      percentageSavings = isNaN(percentageSavings)
        ? 0
        : Math.round(percentageSavings);
      return [percentageCombinations, percentageSavings];
    },
    getSupplierMetrics: (state) => {
      return state.supplier_metrics.map((obj) => ({
        ...obj,
        total_savings_percentage: parseFloat(
          (
            ((obj.km_without_combination - obj.km_with_combination) /
              obj.km_without_combination) *
            100
          ).toFixed(2)
        ),
        total_savings: obj.km_without_combination - obj.km_with_combination,
      }));
    },
    getHistoricSupplierMetrics: (state, getters) => (supplierSelected) => {
      const filteredObjects = getters.getSupplierMetrics.filter(
        (obj) => obj.id_supplier === supplierSelected
      );

      const total_combined = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_combined,
        0
      ).toFixed(2));

      const total_exports = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_exports,
        0
      ).toFixed(2));

      const total_imports = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_imports,
        0
      ).toFixed(2));

      const total_savings = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.total_savings,
        0
      ).toFixed(2));

      const total_late = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.late,
        0
      ).toFixed(2));

      const km_without_combination_sum = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.km_without_combination,
        0
      ).toFixed(2));

      const km_with_combination_sum = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.km_with_combination,
        0
      ).toFixed(2));

      let totals_empty_km = Number(filteredObjects.reduce(
        (acc, obj) => acc + obj.empty_km,
        0
      ).toFixed(2));

      let totals_empty_km_percentage = Number((
        (totals_empty_km / km_with_combination_sum) * 100
      ).toFixed(2));

      let total_savings_percentage = Number((
        ((km_without_combination_sum - km_with_combination_sum) /
          km_without_combination_sum) *
        100
      ).toFixed(2));

      let total_combined_percentage = Number((
        (total_combined / (total_exports + total_imports)) * 100
      ).toFixed(2));

      let total_kpi = Number((
        ((total_exports + total_imports - total_late) /
          (total_exports + total_imports)) *
        100
      ).toFixed(2));

      let total_occupation = Number((filteredObjects.reduce(
        (acc, obj) => acc + obj.occupation,
        0
      ) / filteredObjects.length * 100).toFixed(2));

      total_savings_percentage = isNaN(total_savings_percentage)
        ? 0
        : total_savings_percentage;
      total_combined_percentage = isNaN(total_combined_percentage)
        ? 0
        : total_combined_percentage;
      totals_empty_km_percentage = isNaN(totals_empty_km_percentage)
        ? 0
        : totals_empty_km_percentage;
      total_kpi = isNaN(total_kpi) ? 0 : Math.round(total_kpi);
      total_occupation = isNaN(total_occupation) ? 0 : Math.round(total_occupation);
 
      const total_kilometers = km_without_combination_sum + km_with_combination_sum;
      const total_orders = total_exports + total_imports;

      return {
        total_combined,
        total_exports,
        total_imports,
        total_savings,
        total_savings_percentage,
        total_combined_percentage,
        total_kpi,
        total_occupation,
        totals_empty_km_percentage,
        totals_empty_km,
        total_kilometers,
        total_orders
      };
    },
    getHistoricSupplierDataByDates: (state, getters) => (supplierSelected) => {
      return getters.getSupplierMetrics
        .filter((metric) => metric.id_supplier === supplierSelected)
        .map((metric) => {
          metric.average_occupation = Math.round(metric.occupation * 100);
          metric.average_kpi = Math.round(metric.kpi * 100);
          if (isNaN(metric.total_savings_percentage)) {
            metric.total_savings_percentage = 0;
          }
          if (isNaN(metric.average_kpi)) {
            metric.average_kpi = 0;
          }
          if (isNaN(metric.average_occupation)) {
            metric.average_occupation = 0;
          }
          return metric;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
    },
    getHistoricSupplierData: (state) => {
      const groupedData = {};
      for (const item of state.supplier_metrics) {
        const supplier = item.id_supplier;

        if (!groupedData[supplier]) {
          groupedData[supplier] = {
            id_supplier: supplier,
            supplier_name: item.supplier_name,
            fleet: item.fleet,
            km_without_combination: 0,
            km_with_combination: 0,
            total_services: 0,
            count: 0,
            occupation_sum: 0,
            kpi_sum: 0,
          };
        }

        groupedData[supplier].km_without_combination +=
          item.km_without_combination;
        groupedData[supplier].km_with_combination += item.km_with_combination;
        groupedData[supplier].total_services += item.total_services;
        groupedData[supplier].count++;
        groupedData[supplier].occupation_sum += item.occupation;
        groupedData[supplier].kpi_sum += item.kpi;
      }

      return Object.values(groupedData).map((obj) => ({
        ...obj,
        total_savings_percentage: parseFloat(
          (
            ((obj.km_without_combination - obj.km_with_combination) /
              obj.km_without_combination) *
            100
          ).toFixed(2)
        ),
        average_occupation: Math.round((obj.occupation_sum / obj.count) * 100),
        average_kpi: Math.round((obj.kpi_sum / obj.count) * 100),
      }));
    },
  },
  actions: {
    INITIALIZE_STORE({ getters, commit, dispatch }) {
      const loggedIn = session.isAuthenticated();
      if (loggedIn) {
        commit("SET_ROLE", session.getRole());
        commit("SET_PROFILE_MODE", getters.getRole);
        switch (getters.getProfileMode) {
          case "DISPATCHER":
            dispatch("LOAD_DATA_RECURSIVELY");
            dispatch("SCHEDULE_HOURLY_LOAD_DATA");
            break;
          case "SUPERVISOR":
            dispatch("LOAD_SUPERVISOR_DATA_RECURSIVELY");
            dispatch("SCHEDULE_HOURLY_LOAD_SUPERVISOR_DATA");
            break;
          case "ADMIN":
            dispatch("LOAD_ADMIN_DATA_RECURSIVELY");
            break;
          case "HISTORIC":
            dispatch("LOAD_ADMIN_DATA_RECURSIVELY");
            dispatch("LOAD_HISTORIC_DATA");
            break;
        }
      }
    },
    LOAD_DATA_RECURSIVELY({ dispatch, commit, state }) {
      if (state.data_interval) {
        clearInterval(state.data_interval);
      }

      dispatch("LOAD_DATA");
      const intervalId = setInterval(
        () => dispatch("LOAD_DATA"),
        15 * 60 * 1000
      );
      commit("SET_DATA_INTERVAL", intervalId);
    },
    LOAD_SUPERVISOR_DATA_RECURSIVELY({ dispatch, commit, state }) {
      if (state.data_interval) {
        clearInterval(state.data_interval);
      }

      dispatch("LOAD_SUPERVISOR_DATA");
      const intervalId = setInterval(
        () => dispatch("LOAD_SUPERVISOR_DATA"),
        15 * 60 * 1000
      );
      commit("SET_DATA_INTERVAL", intervalId);
    },
    LOAD_ADMIN_DATA_RECURSIVELY({ dispatch, commit, state }) {
      if (state.data_interval) {
        clearInterval(state.data_interval);
      }

      dispatch("LOAD_ADMIN_DATA");
      const intervalId = setInterval(
        () => dispatch("LOAD_ADMIN_DATA"),
        15 * 60 * 1000
      );
      commit("SET_DATA_INTERVAL", intervalId);
    },
    SCHEDULE_HOURLY_LOAD_DATA({ dispatch, commit, state }) {
      const currentDate = new Date();
      const currentMinute = currentDate.getMinutes();

      // Calculate the minutes remaining until the next hour and add 5 minutes
      const minutesUntilNextHour = (60 - currentMinute) % 60;
      const scheduledMinute = (currentMinute + minutesUntilNextHour + 5) % 60;

      // Calculate the milliseconds until the next scheduled minute
      const millisecondsUntilScheduledMinute =
        (scheduledMinute - currentMinute) * 60 * 1000;

      setTimeout(() => {
        if (state.hour_data_interval) {
          clearInterval(state.hour_data_interval);
        }

        const intervalId = setInterval(
          () => dispatch("LOAD_DATA"),
          60 * 60 * 1000
        );
        commit("SET_HOUR_DATA_INTERVAL", intervalId);
      }, millisecondsUntilScheduledMinute);
    },
    SCHEDULE_HOURLY_LOAD_SUPERVISOR_DATA({ dispatch, commit, state }) {
      const currentDate = new Date();
      const currentMinute = currentDate.getMinutes();

      // Calculate the minutes remaining until the next hour and add 5 minutes
      const minutesUntilNextHour = (60 - currentMinute) % 60;
      const scheduledMinute = (currentMinute + minutesUntilNextHour + 5) % 60;

      // Calculate the milliseconds until the next scheduled minute
      const millisecondsUntilScheduledMinute =
        (scheduledMinute - currentMinute) * 60 * 1000;

      setTimeout(() => {
        if (state.hour_data_interval) {
          clearInterval(state.hour_data_interval);
        }

        const intervalId = setInterval(
          () => dispatch("LOAD_SUPERVISOR_DATA"),
          60 * 60 * 1000
        );
        commit("SET_HOUR_DATA_INTERVAL", intervalId);
      }, millisecondsUntilScheduledMinute);
    },
    LOAD_DATA({ dispatch }) {
      dispatch("LOAD_SAVING_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIER_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIERS_OCCUPATION");
      dispatch("LOAD_ALARMS");
      dispatch("LOAD_LAST_RESOLUTION");
      dispatch("LOAD_MANAGEMENT_AREAS");
      dispatch("LOAD_CONTAINERS");
      dispatch("LOAD_CLIENTS");
      dispatch("LOAD_CHANGE_SUPPLIER_REASONS");
      dispatch("LOAD_BREAK_COMBINATION_REASONS");
      dispatch("LOAD_CHANGE_COMBINATION_REASONS");
      dispatch("LOAD_URGENT_SERVICES");
    },
    LOAD_SUPERVISOR_DATA({ dispatch }) {
      dispatch("LOAD_ORDER_DASHBOARD_INFO");
      dispatch("LOAD_SAVING_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIER_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIERS_OCCUPATION");
      dispatch("LOAD_ALARMS");
      dispatch("LOAD_REVIEW_CHANGES");
      dispatch("LOAD_SUPPLIER_INFO");
      dispatch("LOAD_LAST_RESOLUTION_TIME");
      dispatch("LOAD_COORDINATES");
      
    },
    LOAD_ADMIN_DATA({ dispatch }) {
      dispatch("LOAD_ORDER_DASHBOARD_INFO");
      dispatch("LOAD_SAVING_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIER_DASHBOARD_INFO");
      dispatch("LOAD_SUPPLIERS_OCCUPATION");
      dispatch("LOAD_ALARMS");
      dispatch("LOAD_MANAGEMENT_AREAS");
      dispatch("LOAD_PREFERRED_CARRIERS");
      dispatch("LOAD_DELEGATIONS");
      dispatch("LOAD_DELEGATIONS_PORTS");
      dispatch("LOAD_USERS");
      dispatch("LOAD_ALL_MANAGEMENT_AREAS");
      dispatch("LOAD_SUPPLIER_INFO");
      dispatch("LOAD_AREAS");
      dispatch("LOAD_LAST_RESOLUTION_TIME");
      dispatch("LOAD_ALL_PORTS");
    },
    LOAD_HISTORIC_DATA({ dispatch }) {
      dispatch("LOAD_ALL_PORTS");
    },
    LOAD_LAST_RESOLUTION({ commit, dispatch }) {
      client.get(`/external/solution/data/`).then((response) => {
        if (response.status === 200) {
          commit("SET_LAST_RESOLUTION", response.content);
          dispatch("LOAD_LAST_RESOLUTION_TIME");
        }
      });
    },
    LOAD_LAST_RESOLUTION_TIME({ commit }) {
      client.get(`/external/solution/time/`).then((response) => {
        if (response.status === 200) {
          commit("SET_LAST_RESOLUTION_TIME", response.content);
        }
      });
    },
    LOAD_MANAGEMENT_AREAS({ commit }) {
      client
        .get(`/external/user/${session.getUserId()}/management/areas/`)
        .then((response) => {
          if (response.status === 200) {
            commit("SET_MANAGEMENT_AREAS", response.content.management_areas);
            commit("SET_PORTS", response.content.ports);
            commit("SET_USER", {
              id: response.content.user_id,
              role: response.content.role_id,
            });
          } else if (response.status === 401) {
            if (session.signOut() === true) {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
              this.$router.push("/sign-in");
            }
          }
        });
    },
    LOAD_COORDINATES({ commit }) {
      client.get(`/external/locations/coordinates/`).then((response) => {
        if (response.status === 200) {
          commit("SET_COORDINATES", response.content);
        }
      });
    },
    LOAD_PREFERRED_CARRIERS({ commit }) {
      client.get(`/external/factory/preferred/supplier/`).then((response) => {
        if (response.status === 200) {
          commit("SET_PREFERRED_CARRIERS", response.content);
        }
      });
    },
    LOAD_DELEGATIONS({ commit }) {
      client.get(`/external/delegations/`).then((response) => {
        if (response.status === 200) {
          commit("SET_DELEGATIONS", response.content);
        }
      });
    },
    LOAD_DELEGATIONS_PORTS({ commit }) {
      client.get(`/external/delegations/ports/`).then((response) => {
        if (response.status === 200) {
          commit("SET_DELEGATIONS_PORTS", response.content);
        }
      });
    },
    LOAD_ALL_PORTS({ commit }) {
      client.get(`/external/ports/`).then((response) => {
        if (response.status === 200) {
          commit("SET_ALL_PORTS", response.content);
        }
      });
    },
    LOAD_ALL_MANAGEMENT_AREAS({ commit }) {
      client.get(`/external/management/areas/`).then((response) => {
        if (response.status === 200) {
          commit("SET_ALL_MANAGEMENT_AREAS", response.content);
        }
      });
    },
    LOAD_CONTAINERS({ commit }) {
      client.get(`/external/containers/`).then((response) => {
        if (response.status === 200) {
          commit("SET_CONTAINERS", response.content);
        }
      });
    },
    LOAD_CLIENTS({ commit }) {
      client.get(`/external/locations/`).then((response) => {
        if (response.status === 200) {
          commit("SET_CLIENTS", response.content);
        }
      });
    },
    LOAD_USERS({ commit }) {
      client.get(`/external/users/`).then((response) => {
        if (response.status === 200) {
          commit("SET_USERS", response.content);
        }
      });
    },
    LOAD_ALARMS({ commit }) {
      client.get(`/external/alarms/user/`).then((response) => {
        if (response.status === 200) {
          commit("SET_ALARMS", response.content);
        }
      });
    },
    LOAD_ORDER_DASHBOARD_INFO({ commit }) {
      client.get(`/external/supervisor/kpi/`).then((response) => {
        if (response.status === 200) {
          commit("SET_ORDER_DASHBOARD_INFO", response.content);
        }
      });
    },
    LOAD_SAVING_DASHBOARD_INFO({ commit, state }) {
      let url = "";
      if (state.selected_management_areas.length > 0) {
        const areas = state.selected_management_areas.map(
          (area) => `areas=${area}`
        );
        url += `?${areas.join("&")}`;
      }

      const request1 = client.get(`/external/solution/kpi/`, url);
      const request2 = client.get(`/external/empty/km/`, url);

      Promise.all([request1, request2]).then((responses) => {
        const response1 = responses[0];
        const response2 = responses[1];

        if (response1.status === 200 && response2.status === 200) {
          const combinedContent = {
            ...response1.content,
            ...response2.content,
          };
          commit("SET_SAVING_DASHBOARD_INFO", combinedContent);
        }
      });
    },
    LOAD_SUPPLIER_DASHBOARD_INFO({ commit, state }) {
      let url = "";
      if (state.selected_management_areas.length > 0) {
        const areas = state.selected_management_areas.map(
          (area) => `areas=${area}`
        );
        url += `?${areas.join("&")}`;
      }
      client.get(`/external/supplier/info/`, url).then((response) => {
        if (response.status === 200) {
          commit("SET_SUPPLIER_DASHBOARD_INFO", response.content);
        }
      });
    },
    LOAD_SUPPLIERS_OCCUPATION({ commit, state }) {
      let url = "";
      if (state.selected_management_areas.length > 0) {
        const areas = state.selected_management_areas.map(
          (area) => `areas=${area}`
        );
        url += `?${areas.join("&")}`;
      }
      client.get(`/external/suppliers/occupation/`, url).then((response) => {
        if (response.status === 200) {
          commit("SET_SUPPLIERS_OCCUPATION", response.content);
        }
      });
    },
    LOAD_URGENT_SERVICES({ commit }) {
      client.get(`/external/services/urgent/`).then((response) => {
        if (response.status === 200) {
          commit("SET_URGENT_SERVICES", response.content);
        }
      });
    },
    LOAD_CHANGE_SUPPLIER_REASONS({ commit }) {
      client.get(`/external/motives/supplier/`).then((response) => {
        if (response.status === 200) {
          commit("SET_CHANGE_SUPPLIER_MOTIVES", response.content);
        }
      });
    },
    LOAD_BREAK_COMBINATION_REASONS({ commit }) {
      client.get(`/external/motives/break/`).then((response) => {
        if (response.status === 200) {
          commit("SET_BREAK_COMBINATION_MOTIVES", response.content);
        }
      });
    },
    LOAD_CHANGE_COMBINATION_REASONS({ commit }) {
      client.get(`/external/motives/routes/`).then((response) => {
        if (response.status === 200) {
          commit("SET_CHANGE_COMBINATION_MOTIVES", response.content);
        }
      });
    },
    LOAD_REVIEW_CHANGES({ commit }) {
      client.get(`/external/changes/review/`).then((response) => {
        if (response.status === 200) {
          commit("SET_REVIEW_CHANGES", response.content);
        }
      });
    },
    LOAD_SUPPLIER_INFO({ commit }) {
      client.get(`/external/suppliers/`).then((response) => {
        if (response.status === 200) {
          commit("SET_SUPPLIER_INFO", response.content);
        }
      });
    },
    LOAD_AREAS({ commit }) {
      client.get(`/external/area/`).then((response) => {
        if (response.status === 200) {
          commit("SET_AREAS", response.content);
        }
      });
    },
    UPDATE_PROFILE_MODE({ commit, dispatch }, { profileMode }) {
      switch (profileMode) {
        case "DISPATCHER":
          dispatch("LOAD_DATA_RECURSIVELY");
          dispatch("SCHEDULE_HOURLY_LOAD_DATA");
          break;
        case "SUPERVISOR":
          dispatch("LOAD_SUPERVISOR_DATA_RECURSIVELY");
          dispatch("SCHEDULE_HOURLY_LOAD_SUPERVISOR_DATA");
          break;
        case "ADMIN":
          dispatch("LOAD_ADMIN_DATA_RECURSIVELY");
          break;
        case "HISTORIC":
          dispatch("LOAD_ADMIN_DATA_RECURSIVELY");
          dispatch("LOAD_HISTORIC_DATA");
          break;
      }
      commit("SET_PROFILE_MODE", profileMode);
    },
    async GET_URGENT_PROPOSALS(_, { data }) {
      try {
        const payload = data;
        const response = await client.post(
          `/external/suggest/combinations/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        let responseContent = [];
        switch (response.status) {
          case 200:
            responseContent.content = response.content;
            break;
          case 400:
            responseContent.content = [];
            break;
        }
        return responseContent;
      } catch (error) {
        return false;
      }
    },
    async EXPORT_ORDERS({ dispatch }, { orders }) {
      try {
        let payload = { services: orders };

        const { status } = await client.put(
          `/external/services/export/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );

        if (status === 200) {
          dispatch("LOAD_ALARMS");
        }
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async EXPORT_URGENT_ORDERS({ dispatch, state }) {
      try {
        const payload = {};

        if (state.selected_management_areas.length > 0) {
          payload.management_areas = state.selected_management_areas;
        }

        if (state.selected_containers.length > 0) {
          payload.containers = state.selected_containers;
        }

        if (state.selected_suppliers.length > 0) {
          payload.suppliers = state.selected_suppliers;
        }
        const { status } = await client.put(
          `/external/services/export/early/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        if (status === 200) {
          dispatch("LOAD_ALARMS");
        }
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async CONFIRM_URGENT_ORDERS({ state }) {
      try {
        const payload = {};

        if (state.selected_management_areas.length > 0) {
          payload.management_areas = state.selected_management_areas;
        }

        if (state.selected_containers.length > 0) {
          payload.containers = state.selected_containers;
        }

        if (state.selected_suppliers.length > 0) {
          payload.suppliers = state.selected_suppliers;
        }
        const { status } = await client.put(
          `/external/services/confirm/early/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async CONFIRM_START_DAY_ORDERS({ state }) {
      try {
        const payload = {};

        if (state.selected_management_areas.length > 0) {
          payload.management_areas = state.selected_management_areas;
        }

        if (state.selected_containers.length > 0) {
          payload.containers = state.selected_containers;
        }

        if (state.selected_suppliers.length > 0) {
          payload.suppliers = state.selected_suppliers;
        }
        const { status } = await client.put(
          `/external/services/confirm/rest/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async CONFIRM_ORDER(_, { order }) {
      try {
        let payload;
        let url;
        let serviceId =
          order.type === "import" ? order.import.id : order.export.id;
        switch (order.type) {
          case "combined":
            url = `?import_service_id=${order.import.id}&export_service_id=${order.export.id}`;
            payload = {
              import_service_id: order.import.id,
              export_service_id: order.export.id,
            };
            break;
          default:
            url = `?id_service=${serviceId}`;
            payload = {
              id_service: serviceId,
            };
            break;
        }
        const { status } = await client.put(
          `/external/services/confirm/${url}`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async GET_SUPPLIERS_FOR_ORDER(_, { orderId }) {
      try {
        const response = await client.get(
          `/external/service/${orderId}/supplier/`
        );
        return response.content;
      } catch (error) {
        return false;
      }
    },
    async CHANGE_SUPPLIER_FOR_ORDER(_, { data }) {
      try {
        const orderId = data.orderId;
        const payload = {
          id_motive: data.reason,
          id_supplier: data.supplier,
          comment: data.comment,
        };
        const { status, content } = await client.post(
          `/external/service/${orderId}/supplier/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `access_token ${session.getToken()}`,
          }
        );
        if (status === 201) {
          return content;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    async CHANGE_COMBINATION(_, { data }) {
      try {
        const { status } = await client.post(
          `/external/routes/changes/`,
          data,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `access_token ${session.getToken()}`,
          }
        );
        return status === 201;
      } catch (error) {
        return false;
      }
    },
    async BREAK_COMBINATION(_, { data }) {
      try {
        const payload = {
          id_motive: data.reason,
          import_service: data.importOrder,
          export_service: data.exportOrder,
          comment: data.comment,
        };
        const { status } = await client.post(
          `/external/route/break/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `access_token ${session.getToken()}`,
          }
        );
        return status === 201;
      } catch (error) {
        return false;
      }
    },
    async CHANGE_PASSWORD(_, { password }) {
      try {
        const payload = password;
        const { status } = await client.put(
          `/cornflow/user/${session.getUserId()}/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async FORGOT_PASSWORD(_, { email }) {
      try {
        const payload = email;
        const { status } = await client.put(
          `/cornflow/user/forgot-password/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async MANUAL_SOLVE_BY_PORT(_, { port }) {
      try {
        const { status } = await client.post(
          `/external/manual/solve/${port}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async DOWNLOAD_MANUAL_EXCEL(_, { startDate, endDate }) {
      try {
        const { status, content } = await client.get(
          `/external/weekly/summary/${startDate}/${endDate}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return { status, content };
      } catch (error) {
        return false;
      }
    },
    async UPDATE_REVIEW_CHANGES(_, { reviewChanges, approve }) {
      try {
        let url;
        switch (reviewChanges.type) {
          case "supplier_changes":
            if (reviewChanges.export_id && reviewChanges.import_id) {
              url = `/services/${reviewChanges.import_change_id}/${
                reviewChanges.export_change_id
              }/supplier/${approve ? "apply" : "deny"}`;
            } else {
              const changeId = reviewChanges.id;
              url = `/service/${changeId}/supplier/${
                approve ? "apply" : "deny"
              }`;
            }
            break;
          case "break_combination_changes":
            url = `/route/break/${reviewChanges.id}/${
              approve ? "apply" : "deny"
            }`;
            break;
          case "change_routes":
            url = `/routes/${reviewChanges.id}/${approve ? "apply" : "deny"}`;
            break;
        }

        const { status } = await client.post(`/external${url}/`, {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        });

        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async ASSIGN_NEW_AREA_TO_USER(_, { area }) {
      try {
        const payload = area;
        const response = await client.post(
          `/external/user/management/areas/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return response;
      } catch (error) {
        return false;
      }
    },
    async DELETE_ASSIGNED_AREA_TO_USER(_, { area }) {
      try {
        const { status } = await client.remove(
          `/external/user/management/areas/${area.id}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async ASSIGN_PORT_TO_DELEGATION(_, { port }) {
      try {
        const payload = port;
        const response = await client.post(
          `/external/delegations/ports/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return response;
      } catch (error) {
        return false;
      }
    },
    async DELETE_ASSIGNED_PORT_TO_DELEGATION(_, { port }) {
      try {
        const { status } = await client.remove(
          `/external/delegations/ports/${port.id}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async EDIT_COORDINATE(_, { coordinate }) {
      try {
        const payload = {
          postal_code: coordinate.postal_code,
          province: coordinate.province,
          lat: coordinate.lat,
          long: coordinate.long,
          address: coordinate.address,
          country: coordinate.country,
          town: coordinate.town
        };

        if (coordinate.applied) {
          payload.applied = coordinate.applied ;
        }

        if (coordinate.denied) {
          payload.denied = coordinate.denied;
        }

        const { status } = await client.put(
          `/external/locations/coordinates/${coordinate.id}/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200 || status === 201;
      } catch (error) {
        return false;
      }
    },
    async EDIT_AREA(_, { area }) {
      try {
        const payload = {
          fleet_size: area.fleet_size,
          tilt: area.tilt,
          dangerous_cargo: area.dangerous_cargo,
          reefer: area.reefer,
          max_trucks: parseInt(area.max_trucks),
          disabled: area.disabled,
          deactivated: area.deactivated,
        };
        const { status } = await client.put(
          `/external/suppliers/area/${area.id}/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async DELETE_AREA(_, { area }) {
      try {
        const { status } = await client.remove(
          `/external/suppliers/area/${area.id}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async CREATE_AREA(_, { area }) {
      try {
        area.max_trucks =
          area.max_trucks >= 0 ? parseInt(area.max_trucks) : area.max_trucks;
        const payload = area;
        const { status, content } = await client.post(
          `/external/suppliers/area/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 201 ? content.id : false;
      } catch (error) {
        return false;
      }
    },
    async DELETE_PREFERRED_CARRIER(_, { preferredCarrier }) {
      try {
        const { status } = await client.remove(
          `/external/factory/preferred/supplier/${preferredCarrier.id}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async ADD_PREFERRED_CARRIER(_, { preferredCarrier }) {
      // Convert id_factory and id_supplier from string to int before sending
      preferredCarrier.id_factory = parseInt(preferredCarrier.id_factory);
      preferredCarrier.id_supplier = parseInt(preferredCarrier.id_supplier);
      const payload = preferredCarrier;
      const { status, content } = await client.post(
        `/external/factory/preferred/supplier/`,
        payload,
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      );
      return status === 201 ? content : false;
    },
    async ADD_DELEGATION(_, { delegation }) {
      // Convert id_factory and id_supplier from string to int before sending

      const payload = {
        name: delegation.name,
        road_rail_combine: delegation.road_rail_combine,
      };

      const { status, content } = await client.post(
        `/external/delegations/`,
        payload,
        {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }
      );
      return status === 201 ? content : false;
    },
    async DELETE_DELEGATION(_, { delegation }) {
      try {
        const { status } = await client.remove(
          `/external/delegations/${delegation.id}/`,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        return status === 200;
      } catch (error) {
        return false;
      }
    },
    async LOAD_PORT_METRICS({ commit }, { data }) {
      try {
        if (data === null || data.length === 0) {
          commit("SET_PORT_METRICS", []);
        } else {
          const dateFrom = moment(data.date_from);
          data.date_from = dateFrom.format("YYYY-MM-DD");
          const dateTo = moment(data.date_to);
          data.date_to = dateTo.format("YYYY-MM-DD");
          const response = await client.get(
            `/external/dashboard/ports/info/${data.date_from}/${data.date_to}/`,
            {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          );
          if (response.status === 200) {
            commit("SET_PORT_METRICS", response.content);
          }
        }
      } catch (error) {
        return false;
      }
    },
    async LOAD_SUPPLIER_METRICS({ commit }, { data }) {
      try {
        if (data === null || data.length === 0) {
          commit("SET_SUPPLIER_METRICS", []);
        } else {
          const dateFrom = moment(data.date_from);
          data.date_from = dateFrom.format("YYYY-MM-DD");
          const dateTo = moment(data.date_to);
          data.date_to = dateTo.format("YYYY-MM-DD");
          const response = await client.get(
            `/external/dashboard/suppliers/info/${data.date_from}/${data.date_to}/`,
            {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            }
          );
          if (response.status === 200) {
            commit("SET_SUPPLIER_METRICS", response.content);
          }
        }
      } catch (error) {
        return false;
      }
    },
    async UPDATE_PREFERRED_CARRIER({ commit }, { preferredCarrier }) {
      try {
        const payload = {
          combinable: preferredCarrier.combinable,
          id_area: preferredCarrier.id_area,
          id_factory: preferredCarrier.id_factory,
          id_supplier: preferredCarrier.id_supplier,
          ignore_in_model: preferredCarrier.ignore_in_model
        };

        const { status } = await client.put(
          `/external/factory/preferred/supplier/${preferredCarrier.id}/`,
          payload,
          {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          }
        );
        if (status === 200) {
          commit('UPDATE_PREFERRED_CARRIER', preferredCarrier);
          return true;
        }
        return false;
      } catch (error) {
        console.error("Error updating preferred carrier:", error);
        return false;
      }
    },
  },
  mutations: {
    SET_DATA_INTERVAL(state, payload) {
      state.data_interval = payload;
    },
    SET_HOUR_DATA_INTERVAL(state, payload) {
      state.hour_data_interval = payload;
    },
    SET_ROLE(state, payload) {
      state.role = payload;
    },
    SET_PROFILE_MODE(state, payload) {
      state.profileMode = payload;
    },
    SET_LAST_RESOLUTION(state, payload) {
      state.last_resolution = payload;
      state.orderManager = new OrderManager(payload);
    },
    SET_LAST_RESOLUTION_TIME(state, payload) {
      state.last_resolution_time = payload;
    },
    SET_PORTS(state, payload) {
      state.ports = payload;
    },
    SET_MANAGEMENT_AREAS(state, payload) {
      state.management_areas = payload;
    },
    SET_PREFERRED_CARRIERS(state, payload) {
      state.preferred_carriers = payload;
    },
    SET_COORDINATES(state, payload) {
      state.coordinates = payload;
      state.coordinate_changes_formatted = payload
    },
    SET_DELEGATIONS(state, payload) {
      state.delegations = payload;
    },
    SET_DELEGATIONS_PORTS(state, payload) {
      state.delegations_ports = payload;
    },
    SET_ALL_PORTS(state, payload) {
      state.all_ports = payload;
    },
    SET_ALL_MANAGEMENT_AREAS(state, payload) {
      state.all_management_areas = payload;
    },
    SET_ALL_SUPPLIERS(state, payload) {
      state.all_suppliers = payload;
    },
    SET_CONTAINERS(state, payload) {
      state.containers = payload;
    },
    SET_CLIENTS(state, payload) {
      state.clients = payload;
    },
    SET_ALARMS(state, payload) {
      state.alarms = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_ORDER_DASHBOARD_INFO(state, payload) {
      state.orders_dashboard_info = payload;
    },
    SET_SUPPLIER_DASHBOARD_INFO(state, payload) {
      state.suppliers_dashboad_info = payload;
    },
    SET_SUPPLIERS_OCCUPATION(state, payload) {
      state.suppliers_occupation = payload;
    },
    SET_SAVING_DASHBOARD_INFO(state, payload) {
      state.savings_dashboard_info = payload;
    },
    SET_URGENT_SERVICES(state, payload) {
      state.urgent_services = new OrderManager(payload, true);
    },
    SET_REVIEW_CHANGES(state, payload) {
      state.review_changes = payload;
      state.review_changes_formatted =
        adminManager.formatReviewChanges(payload);
    },
    SET_REVIEW_CHANGES_FORMATTED(state, payload) {
      state.review_changes_formatted = payload;
    },
    SET_COORDINATE_CHANGES_FORMATTED(state, payload) {
      state.coordinate_changes_formatted = payload;
    },
    SET_REVIEW_CHANGE_FORMATTED(state, payload) {
      const reviews = state.review_changes_formatted;
      const index = payload.index;

      if (index >= 0 && index < reviews.length) {
        reviews[index] = payload.review;
      }
    },
    SET_COORDINATE_CHANGE_FORMATTED(state, payload) {
      const coordinates = state.coordinate_changes_formatted;
      const index = payload.index;

      if (index >= 0 && index < coordinates.length) {
        coordinates[index] = payload.review;
      }
    },
    SET_SUPPLIER_INFO(state, payload) {
      state.supplier_area = payload;
    },
    SET_AREAS(state, payload) {
      state.areas = payload;
    },
    SET_SELECTED_MANAGEMENT_AREAS(state, payload) {
      state.selected_management_areas = payload;
    },
    SET_SELECTED_SUPPLIERS(state, payload) {
      state.selected_suppliers = payload;
    },
    SET_SELECTED_CONTAINERS(state, payload) {
      state.selected_containers = payload;
    },
    SET_SELECTED_ORDER_ID(state, payload) {
      state.selected_order_id = payload;
    },
    SET_SELECTED_DATES(state, payload) {
      state.selected_dates = payload;
    },
    SET_SELECTED_PORT_FOR_HISTORIC_DASHBOARD(state, payload) {
      state.selected_port_for_historic_dashboard = payload;
    },
    SET_SELECTED_SUPPLIER_FOR_HISTORIC_DASHBOARD(state, payload) {
      state.selected_supplier_for_historic_dashboard = payload;
    },
    SET_URGENT_ORDERS(state, payload) {
      const newOrders = [...state.urgent_services.orders];
      payload.forEach((updatedOrder) => {
        const index = newOrders.findIndex(
          (order) => order.id === updatedOrder.id
        );
        if (index !== -1) {
          newOrders[index].checked = updatedOrder.checked;
          newOrders[index].exported = updatedOrder.exported;
        }
      });
      state.urgent_services.orders = newOrders;
    },
    SET_URGENT_ORDER(state, payload) {
      const urgentOrders = state.urgent_services.orders;
      const index = payload.index;

      if (index >= 0 && index < urgentOrders.length) {
        urgentOrders[index] = payload.order;
      }
    },
    SET_CONFIRMED_ORDERS(state, payload) {
      const newOrders = [...state.orderManager.orders.confirmed];
      payload.forEach((updatedOrder) => {
        const index = newOrders.findIndex(
          (order) => order.id === updatedOrder.id
        );
        if (index !== -1) {
          newOrders[index].checked = updatedOrder.checked;
          newOrders[index].exported = updatedOrder.exported;
        }
      });
      state.orderManager.orders.confirmed = newOrders;
    },
    SET_CONFIRMED_ORDER(state, payload) {
      const confirmedOrders = state.orderManager.orders.confirmed;
      const index = payload.index;

      if (index >= 0 && index < confirmedOrders.length) {
        confirmedOrders[index] = payload.order;
      }
    },
    SET_NON_CONFIRMED_ORDERS(state, payload) {
      const newOrders = [...state.orderManager.orders.nonConfirmed];
      payload.forEach((updatedOrder) => {
        const index = newOrders.findIndex(
          (order) => order.id === updatedOrder.id
        );
        if (index !== -1) {
          newOrders[index].checked = updatedOrder.checked;
          newOrders[index].confirmed = updatedOrder.confirmed;
        }
      });
      state.orderManager.orders.nonConfirmed = newOrders;
    },
    SET_NON_CONFIRMED_ORDER(state, payload) {
      const nonConfirmedOrders = state.orderManager.orders.nonConfirmed;
      const index = payload.index;

      if (index >= 0 && index < nonConfirmedOrders.length) {
        nonConfirmedOrders[index] = payload.order;
      }
    },
    SET_EXPORTED_ORDERS(state, payload) {
      state.orderManager.orders.exported = payload;
    },
    ADD_CONFIRMED_ORDERS(state, payload) {
      state.orderManager.orders.confirmed.push(...payload);
    },
    ADD_EXPORTED_ORDERS(state, payload) {
      state.orderManager.orders.exported.push(...payload);
    },
    ADD_NON_CONFIRMED_ORDERS(state, payload) {
      state.orderManager.orders.nonConfirmed.push(...payload);
    },
    SET_CHANGE_SUPPLIER_MOTIVES(state, payload) {
      state.change_supplier_reasons = payload;
    },
    SET_BREAK_COMBINATION_MOTIVES(state, payload) {
      state.break_combination_reasons = payload;
    },
    SET_CHANGE_COMBINATION_MOTIVES(state, payload) {
      state.change_combination_reasons = payload;
    },
    SET_PORT_METRICS(state, payload) {
      state.port_metrics = payload;
    },
    SET_SUPPLIER_METRICS(state, payload) {
      state.supplier_metrics = payload;
    },
    UPDATE_PREFERRED_CARRIER(state, updatedCarrier) {
      const index = state.preferred_carriers.findIndex(carrier => carrier.id === updatedCarrier.id);
      if (index !== -1) {
        state.preferred_carriers.splice(index, 1, updatedCarrier);
      }
    },
  },
});

export default store;
